<template>
  <div
    class="cards container van-tab__panel"
    :style="{ backgroundImage: 'url(img/pro-bg.png)' }"
  >
    <van-loading v-if="loading" />
    <div
      class="cardlist shadow border-r5 mt-10 noflex"
      v-for="item in banks"
      :key="item.id"
      @click="goPage('detail', item.id)"
    >
      <div class="con tophead">
        <van-icon name="card" style="margin-left: 6px" />
        <span>{{ item.account }}</span>
      </div>
      <div class="line fz14 con">
        {{ $t("bank.text1") }}:{{ item.account_name }}
      </div>
      <div class="fz14 con">* {{ $t("bank.text1") }}</div>
      <!-- <a class="link">{{ $t("bank.text16")}}</a> -->
    </div>
    <div
      class="tophead shadow default border-r5 con mt-10"
      @click="goPage('add')"
      v-if="!loading && !banks.length"
    >
      <van-icon name="peer-pay" style="margin-left: 6px" />
      <span>{{ $t("bank.text3") }}</span>
      <van-icon name="plus" style="font-size: 14px" />
    </div>
    <!-- <div class="tophead shadow default border-r5 con mt-10" @click="goPage('change')" v-else>
      <van-icon name="idcard" style="margin-left: 6px;" />
      <span>更改银行卡</span>
      <van-icon name="edit" style="font-size: 14px;" />
    </div> -->
  </div>
</template>

<script setup>
import { ref } from "vue";
import { Dialog } from "vant";
import { getBanks } from "@/api/user";
import { useRouter } from "vue-router";
const loading = ref(true);
const banks = ref([]);
const router = useRouter();
const goPage = (type, id) => {
  router.push({ name: "cardAdd", query: { type: type, id: id } });
};
const getData = () => {
  getBanks()
    .then((res) => {
      banks.value = res.data.user_banks;
      loading.value = false;
      if (!banks.value.length) {
        Dialog.confirm({
          title: "添加银行卡",
          message: "还未绑定银行卡，是否添加？",
        }).then(() => {
          goPage("add");
        });
      }
      console.log(res);
    })
    .finally(() => {
      loading.value = false;
    });
};
getData();
</script>

<style lang="less" scoped>
.cards {
  background-repeat: no-repeat;
  background-size: 80% auto;
  background-position: 0 -50px;
  color: #ddd;

  .cardlist {
    background: linear-gradient(180deg, #302e55, #514f79);
  }

  .tophead {
    display: flex;
    justify-content: left;
    /* 水平居中 */
    align-items: center;
    justify-content: space-between;
    font-size: 18px;

    i:first-child {
      font-size: 40px;
      margin-right: 20px;
    }

    span {
      width: 80%;
    }
  }
  .link {
    float: right;
  }
  .line {
    background: #2d2c58;
    color: #bbb;
    margin: 10px 0;
    padding: 10px;
    border-top: 1px solid #27273a;
    border-bottom: 1px solid #66658f;
  }
}
</style>
